:root {
  /**
  * Tipos de fuente ordenadas de menor a mayor grosor.
  * ff- = font-family
  * fs- = font-size
  * fc- = color (color de la fuente)
  */

  /* Regular */
  --ff-regular-thin: 'Helvetica Regular Thin';
  --ff-regular-light: 'Helvetica Regular Light';
  --ff-regular: 'Helvetica Regular';
  --ff-regular-medium: 'Helvetica Regular Medium';

  /* Italic */
  --ff-italic-thin: 'Helvetica Regular Thin Italic';
  --ff-italic-light: 'Helvetica Regular Light Italic';
  --ff-italic: 'Helvetica Regular Italic';
  --ff-italic-medium: 'Helvetica Regular Medium Italic';

  /* Semi-bold */
  --ff-semibold: 'Helvetica Semi Bold';
  --ff-semibold-italic: 'Helvetica Semi Bold Italic';

  /* Bold */
  --ff-bold: 'Helvetica Bold';
  --ff-bold-italic: 'Helvetica Bold Italic';

  /**
  * Fuentes ordenadas de menor a mayor tamaño.
  */
  --fs-xs: 0.9rem;
  --fs-sm: 1.2rem;
  --fs-md: 1.6rem;
  --fs-lg: 2rem;
  --fs-xl: 2.4rem;
  --fs-xxl: 2.8rem;

  /**
  * Variables de fuente global
  */
  --ff: var(--ff-regular-light);
  --fc: 0, 0, 0;

  /**
  * Variables de colores
  */
  --primary-color: 26, 4, 39;
  --secondary-color: 255, 131, 37;
  --tertiary-color: 12, 12, 15;
  --fourth-color: 58, 0, 84;
  --fifth-color: 79, 70, 229;

  --error-strong: 220, 38, 38;
  --error-light: 240, 169, 167;

  /**
  * Colores de la barra de desplazamiento
  */
  --scrollbar-color: 0, 0, 0;
  --scrollbar-color-corner: 0, 0, 0;
  --scrollbar-color-thumb: 0, 0, 0;

  /**
  * Fondos
  */
  --bg-squares: url('/assets/images/backgrounds/onyze_bg.png');
  --bg-circles: url('/assets/images/backgrounds/onyze_bg2.png');
  --bg-triangles: url('/assets/images/backgrounds/onyze_bg3.png');

  /*
  * Banderas de paises
  */
  --flag-es: url('/assets/images/flags/es.svg');
  --flag-en: url('/assets/images/flags/en.svg');
  --flag-ca: url('/assets/images/flags/ca.svg');

  /**
  * ADMIN
  */
  --logo: url('/assets/images/logo/onyze_black.png');
  --logo-min: url('/assets/images/logo/onyze_black_min.png');
  --logo-dark: url('/assets/images/logo/onyze_white.png');
  --logo-dark-min: url('/assets/images/logo/onyze_white_min.png');
  --dashboard-bg: url('/assets/images/backgrounds/bg_dashboard.png');

  --admin-bg: 248, 249, 250;
  --header-bg: 255 255 255 / 4%;
  --border-line-color: 229, 231, 235;

  --menu-bg: 255, 255, 255;
  --menu-color: 0 0 0 / % 45;
  --menu-border-color: 23, 7, 32;
  --menu-expanded-bg: var(--menu-bg);
  --menu-item-bg-hover: 255, 255, 255;
  --menu-item-color-hover: var(--menu-color);

  --overlay-bg: 243, 243, 243;

  --sidebar-logo: var(--logo);
  --sidebar-logo-min: var(--logo-min);

  --input-bg: 255, 255, 255;
  --input-border-error-color: 240, 169, 167;

  // Active & Hover
  --active-color: var(--menu-bg);
  --hover-color: var(--menu-bg);
  --focus-border-color: var(--secondary-color);

  --skeleton-bg: 216, 216, 216;
  --skeleton-gradient-effect: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.4),
    rgba(255, 255, 255, 0)
  );

  --editor-bg: 255, 255, 255;
  --textarea-color: 0, 0, 0;
  --textarea-bg: 240, 240, 240;
}
