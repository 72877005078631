[data-theme='DARK'] {
  --fc: 255, 255, 255;

  --admin-bg: 31, 32, 33;
  --header-bg: var(--admin-bg) / 4%;
  --border-line-color: 39, 40, 41;

  --menu-bg: 18, 18, 18;
  --menu-border-color: 23, 7, 32;
  --menu-color: 201, 201, 209;
  --menu-expanded-bg: 20, 20, 20;
  --menu-item-bg-hover: 13, 13, 13;
  --menu-item-color-hover: gray;

  --overlay-bg: 41, 41, 41;

  --sidebar-logo: var(--logo-dark);
  --sidebar-logo-min: var(--logo-dark-min);

  --input-bg: 23, 24, 24;
  --input-border-error-color: 187, 72, 69;

  --active-color: var(--menu-bg);
  --hover-color: 43, 44, 45;

  --focus-border-color: var(--secondary-color);
  --skeleton-bg: 41, 41, 41;
  --skeleton-gradient-effect: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0)
  );

  --editor-bg: 240, 240, 240;
  --textarea-color: 0, 0, 0;
  --textarea-bg: 240, 240, 240;
}
